<template>
  <div class="mx-1">
    <h2 class="custom-title-secondary mb-1">
      Qual o regime tributário utilizado pela empresa?
    </h2>
    <selectOptionsCard
      v-model="formUser.temporaryJuridicType"
      :optionsToChoice="currentRegimeList"
      @onAnswer="onAnswer($event)"
      :isValid="isValid"
      :rules="[(v) => !!v || 'escolha uma das opcões']"
    ></selectOptionsCard>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import selectOptionsCard from '@components/registering/selectOptionsCard.vue'

export default {
  components: {
    selectOptionsCard,
  },
  props: {
    answer: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    ...mapState({
      formUser: (state) => state.formDataUser.formUser,
    }),
    currentRegimeList() {
      if (this.formUser.hasPartner)
        return this.choices.filter((item) => item.label != 'MEI')
      return this.choices
    },
  },
  data() {
    return {
      choices: [
        {
          label: 'MEI',
          value: 'mei',
        },
        {
          label: 'Simples Nacional',
          value: 'simples nacional',
        },
        {
          label: 'Outros',
          value: 'outros',
        },
      ],
      loading: false,
    }
  },
  methods: {
    onAnswer(newValue) {
      this.formUser.temporaryJuridicType = newValue
      console.log(this.formUser, newValue)
      this.$emit('onAnswer', {
        temporaryJuridicType: this.formUser.temporaryJuridicType,
      })
    },
  },

  created() {
    if (this.formUser.temporaryJuridicType instanceof Object) {
      this.formUser.temporaryJuridicType = this.formUser.temporaryJuridicType?.value
    }
  },
}
</script>

<style module lang="scss">
@import '@design';
</style>
